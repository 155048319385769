// Fonts
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Roboto+Slab&display=swap');
@import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.7.2/font/bootstrap-icons.css");

// Variables
@import 'variables';

// Bootstrap
//@import "~bootstrap/scss/bootstrap";

// Web Pixels
@import "@webpixels/css";

h1, .h1 {
  font-size: 2.5em;
  color: $white;
}

h2, .h2 {
  font-size: 2em;
  color: $dpp-blue-2;
}

h3, .h3 {
  font-size: 1.5em;
}

.bg-metrix-body {
  --bs-bg-opacity: 0;
  background-color: white !important;
}

//.btn-metrix-teal {
//  color: #fff;
//  background-color: $metrix-teal;
//  border-color: $black;
//}
//
//.btn-metrix-teal:hover,
//.btn-metrix-teal:active,
//.btn-metrix-teal:focus,
//.btn-metrix-teal.active {
//  background: lighten($metrix-teal, 10);
//  color: #fff;
//  border-color: $black;
//}
