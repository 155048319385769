// Typography
$font-family-sans-serif: 'Roboto Slab', Georgia, sans-serif;
$font-size-base: 1rem;
$line-height-base: 1.6;

$metrix-purple: #870e44;
//$metrix-teal: #335c67;
$metrix-teal: #a4493d;
$metrix-yellow: #f8ba12;
$metrix-dark-grey: #616565;
$metrix-light-grey: #dde4e4;
$metrix-red: #d62828;
$metrix-yellow-two: #FBAC0E;
$metrix-green: #157F1F;
//$metrix-green: #d4eb8e;
//$metrix-green: #789f90;
$metrix-blue: #007090;
$dpp-blue: #5b7f95;
$dpp-blue-2: #385e9d;

// Body
$body-bg: $dpp-blue;

// Colors
$blue: $metrix-blue;
$indigo: $metrix-teal;
$purple: $metrix-purple;
$pink: #f66d9b;
$red: $metrix-red;
$orange: $metrix-yellow-two;
$yellow: $metrix-yellow;
$green: $metrix-green;
$teal: $metrix-teal;
$cyan: #6cb2eb;

$primary: $metrix-teal;

$success: $green;
$danger: $red;
$warning: $yellow;

$headings-font-family: 'Roboto Slab', serif;